import { initializeComponent } from '@/js/functions/init'
import props from '@/js/functions/props'
import DotstateMap from '@/js/views/dotstate-map.vue'

initializeComponent('dotstatemap', init)

export default function init(rootElement) {
  const initProps = props.parseProps(rootElement)
  // console.log('init dotstatement', initProps);
  new Vue({
    el: `#${rootElement.id}`,
    propData: initProps,
    components: {
      DotstateMap,
    },
    props: ['purl', 'states'],
    data() {
      return {
        elementId: rootElement.id,
        isMounted: false,
        stateData: null,
        purl: null,
        isReadOnly: false,
        model: null,
        errorMessage: null,
      }
    },
    mounted() {
      console.log('dotstatemap mounted', initProps)
      this.purl = ''
      this.isReadOnly = false

      if (initProps) {
        this.purl = initProps.purl
        this.stateData = initProps.states
      }
      this.isMounted = true
    },
  })
}
